// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
import Projects from "@/components/Projects";
import Account from "@/components/Account";
import Login from "@/components/Login";
import Logout from "@/components/Logout";
import VueRouter from "vue-router";
/*
import Nodes from "@/components/Nodes";
import Hotspots from "@/components/Hotspots";
import PanoEditor from "@/components/PanoEditor";
 */
import Root from "@/components/Root";
import PageNotFound from "@/components/PageNotFound";
import User from "@/components/User";

const routes = [
	{path: '/', component: Root},
	{path: '/login', component: Login},
	{path: '/projects', component: Projects},
	{path: '/account', component: Account},
//    { path: '/stats', component: Stats },

	/*
		{ path: '/nodes', component: Nodes },
		{ path: '/hotspots', component: Hotspots },
		{ path: '/panoeditor', component: PanoEditor }, */
	{path: '/logout', component: Logout},
	{path: '/user', component: User},
	{
		path: "/:catchAll(.*)", name: "NotFound", component: PageNotFound,
		meta: {
			requiresAuth: false
		}
	}
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = new VueRouter({
	routes // short for `routes: routes`
})

