<template>
  <span>
        <div v-if="(account.storage)" style="text-align: left">

          <span v-if="busy">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
          </span>
          <span v-else>
            <span v-if="!account.storage?.certificate?.arn">
              <div class="todo">1. Request a Certificate for your domain</div>
              <p>Enter the <a href="https://en.wikipedia.org/wiki/Fully_qualified_domain_name">fully qualified domain name</a> that should be assigned to your account.
              <br>Please note: You will need to be able to create a CNAME DNS entry for that domain in the next steps.
              </p>

              <b-form-input type="text" v-model="certDomain" class="col-4" placeholder="pano.example.org"/>
              <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="requestCertificateClick">Request
              </button>
              <hr style="clear: both">
                <div class="todo next">2. Create the distribution</div>
                <div class="todo next">3. Forward your domain to the distribution</div>
            </span>
            <span v-else>
              <span v-if="!account.storage.cloudfront">
                <div class="todo done">1. Certificate Requested</div>
                <div class="todo">2. Validate the domain and create the distribution</div>
                <div v-if="certificateStatus?.DomainName">
                  <hr style="clear: both">
                  <div class="todo_text">Please add the following DNS record to validate your domain:</div>
                  <div v-for="opt in certificateStatus.DomainValidationOptions">
                    <span v-if="opt.ResourceRecord">
                      <DnsRecord :type="opt.ResourceRecord?.Type" :value="opt.ResourceRecord?.Value"
                                 :name="shortCName(opt.ResourceRecord?.Name)"></DnsRecord>
                    </span><br>
                    Record Status: {{ opt.ValidationStatus }}<br>
                  </div>
                  DomainName: {{ certificateStatus.DomainName }}<br>
                  Certificate Status: {{ certificateStatus.Status }}<br>
                </div>
                <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="certificateStatusClick">Refresh status</button>
                  <hr style="clear: both">
                  <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="createCloudfrontClick"
                          :disabled="certificateStatus?.Status!='ISSUED'">Create
                    Distribution
                  </button>
                <button class="btn btn-outline-secondary float-left my-2 mt-3"
                        @click="deleteCertClick">Delete Request</button>
              <hr style="clear: both">
              <div class="todo next">3. Forward your domain to the distribution</div>
              </span>

            <span v-else>
              <span v-if="account.storage.certificate.domain==account.storage.publicDomain">
                <div>
                  <span v-if="(account.storage) && (account.storage.publicDomain)">
                    Custom Domain: <b-form-input type="text" disabled v-model="account.storage.publicDomain"/>
                </span>
                  <span v-if="account.showBeta">
                    <hr style="clear: both">
                     <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="disableCloudfrontClick">Disable
                       Cloudfront
                       </button>
                  </span>
                </div>
              </span>
              <span v-else>
                <div class="todo done">1. Certificate Requested</div>
                <div class="todo done">2. Distribution created</div>
                <div class="todo">3. Forward your domain to the distribution</div>
                <p>Please note: This can take from several minutes up to 48hrs to propagate to all DNS servers.</p>
                <DnsRecord type="CNAME" :value="account.storage.cloudfrontDomain + '.'"
                           :name="shortCName(account.storage.certificate.domain + '.')"></DnsRecord>
            <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="enableCloudfrontClick">Enable
              Distribution
            </button>
                  <span v-if="account.showBeta">
                    <hr style="clear: both">
                    If something goes wrong:<br>
                   <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="disableCloudfrontClick">Disable
                    Cloudfront
                    </button>
                  <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="deleteCloudfrontClick">Delete
                   Cloudfront
                 </button>
                  </span>
              </span>
            </span>
            </span>
          </span>
          <!--
<hr style="clear: both">
          <br><br><br><br><br><br><br><br><br><br><br><br>
           <h3>Debug</h3>
                        Domain:
               <b-form-input type="text" disabled :value="account.storage?.certificate?.domain"/>
                Arn:
               <b-form-input type="text" disabled :value="account.storage?.certificate?.arn"/>
           Cloudfront:
               <b-form-input type="text" disabled :value="account.storage?.cloudfront"/>
           Cf-Domain:
               <b-form-input type="text" disabled :value="account.storage?.cloudfrontDomain"/>
                -->

        </div>


   </span>
</template>

<script>
import Vue from 'vue'
import {mapState, mapActions} from 'vuex'
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"
import AccountStats from "@/components/AccountStats";
import AccountUsage from "@/components/AccountUsage";
import AccountLabel from "@/components/AccountLabel";
import axios from "axios";
import DnsRecord from "@/components/DnsRecord.vue";

export default {
  name: 'AccountCustomDomain',
  components: {DnsRecord},
  props: ['account'],
  data() {
    return {
      windowWidth: window.innerWidth,
      certDomain: "",
      certificateStatus: null,
      busy: false,
    }
  },
  mixins: [HelperFunctionsMixin],
  computed: {
    ...mapState('accounts', ['accounts']),
    ...mapState('navigation', ['currentAccount']),
    ...mapState('user', ['userId']),
  },
  mounted: function () {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
    if (this.account?.storage?.certificate?.arn && !(this.account?.storage?.cloudfront)) {
      this.certificateStatusClick();
    }
  },
  methods: {
    ...mapActions({alertInformation: 'alert/information', alertSuccess: 'alert/success', alertError: 'alert/error'}),
    ...mapActions({
      getAccount: "accounts/getAccount",
      requestCertificate: "accounts/requestCertificate",
      getCertificateStatus: "accounts/getCertificateStatus",
      createCloudfront: "accounts/createCloudfront",
      deleteCertificate: "accounts/deleteCertificate",
      setEnabledCloudfront: "accounts/setEnabledCloudfront",
      deleteCloudfront: "accounts/deleteCloudfront",

    }),

    async requestCertificateClick() {
      let r = await this.requestCertificate({account: this.account, domain: this.certDomain});
      if (r && r.storage) {
        this.$emit('updateAccount', r);
        setTimeout(this.certificateStatusClick, 100);
        setTimeout(this.certificateStatusClick, 5000);
        setTimeout(this.certificateStatusClick, 10000);
        //      this.account = r;
      }
    },
    async certificateStatusClick() {
      let r = await this.getCertificateStatus({account: this.account});
      if (r && r.Certificate) {
        this.certificateStatus = r.Certificate;
      }
    },
    async createCloudfrontClick() {
      try {
        this.busy = true;
        let r = await this.createCloudfront({account: this.account});
        if (r && r.storage) {
//          this.account.storage = r.storage;
          this.$emit('updateAccount', r);
        }
      } finally {
        this.busy = false;
      }
    },
    async deleteCertClick() {
      let r = await this.deleteCertificate({account: this.account});
      if (r && r.storage) {
//        this.account = r;
        this.$emit('updateAccount', r);
      }
    },
    async disableCloudfrontClick() {
      let r = await this.setEnabledCloudfront({account: this.account, value: false});
      if (r && r.storage) {
//        this.account = r;
        this.$emit('updateAccount', r);
      }
    },
    async enableCloudfrontClick() {
      let r = await this.setEnabledCloudfront({account: this.account, value: true});
      if (r && r.storage) {
//        this.account = r;
        this.$emit('updateAccount', r);
      }
    },
    async deleteCloudfrontClick() {
      try {
        this.busy = true;
        let r = await this.deleteCloudfront({account: this.account});
        if (r && r.storage) {
//          this.account = r;
          this.$emit('updateAccount', r);
        }
      } finally {
        this.busy = false;
      }
    },
    shortCName(v) {
      let dom = String(this.account?.storage?.certificate?.domain);
      const i = dom.indexOf(".");
      if (i >= 0) {
        dom = dom.substring(i);
        return v.replace(dom + ".", "");
      } // domain with dot!
      return v;
    }
  }
}

</script>

<style scoped>
h1 {
  padding: 15px 0;
}

.todo {
  font-size: 22px;
}

.todo.done {
  text-decoration: line-through;
  color: #ccc;
}

.todo_text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: larger;
}

.todo.next {
  color: #aaa;
}

</style>
