<template>
  <b-modal id="access-modal" title="Access Settings" header-bg-variant="light" @ok="updateAccess" ok-title="Update">
    <form @submit.prevent="updateAccess">
      <label for="restriction" class="form-control-label">Restriction:</label>
      <b-form-select id="restriction" v-model="restriction" class="mb-3">
        <b-form-select-option value="none">None</b-form-select-option>
        <b-form-select-option value="block">Block</b-form-select-option>
        <b-form-select-option value="credentials">User/Password</b-form-select-option>
        <b-form-select-option value="redirect">Redirect</b-form-select-option>
      </b-form-select>
      <span v-if="restriction=='block'">
        <label for="message" class="form-control-label">Message:</label>
        <b-form-input id="message" v-model="message" placeholder="access denied"></b-form-input>
      </span>
      <span v-if="restriction=='credentials'">
        <label for="username" class="form-control-label">User Name:</label>
        <b-form-input id="username" v-model="username" placeholder=""></b-form-input>
        <label for="password" class="form-control-label">Password:</label>
        <b-form-input type="password" id="password" v-model="password" placeholder=""></b-form-input>
      </span>
      <span v-if="restriction=='redirect'">
        <label for="redirect" class="form-control-label">Redirect Url:</label>
        <b-form-input id="redirect" type="url" v-model="redirectUrl" placeholder=""></b-form-input>
      </span>
    </form>
  </b-modal>
</template>

<script>
import {mapActions} from 'vuex'
import QrcodeVue from 'qrcode.vue'
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"

export default {
  name: 'AccessModal',
  data() {
    return {
      restriction: "none",
      username: "",
      password: "",
      message: "",
      redirectUrl: "",
    }
  },
  watch: {
    project(val) {
      let access = val.access;
      this.restriction = access.restriction;
      if (access.credentials && (access.credentials.length > 0)) {
        this.username = access.credentials[0].username;
        this.password = "";
      } else {
        this.username = "";
        this.password = "";
      }
      this.message = access.message;
      this.redirectUrl = access.redirectUrl;
    }
  },
  components: {
    QrcodeVue,
  },
  mixins: [HelperFunctionsMixin],
  props: ['project'],
  computed: {},
  methods: {
    ...mapActions({alertSuccess: 'alert/success', alertInformation: 'alert/information', alertError: 'alert/error'}),
    ...mapActions({updateProjectAccess: 'projects/updateProjectAccess'}),
    async updateAccess() {
      const access = this.project.access;
      access.restriction = this.restriction;
      if (this.restriction == "block") {
        access.message = this.message;
      }
      if (this.restriction == "credentials") {
        access.credentials = [{username: this.username, password: this.password}];
      }
      if (this.restriction == "redirect") {
        access.redirectUrl = this.redirectUrl;
      }
      console.log(access);
      await this.updateProjectAccess(this.project);
    }
  }
}
</script>