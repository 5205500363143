<template>
  <div style="border: black 1px solid;border-radius: 5px; padding: 10px;">
    <h4 style="text-align: center">Current Usage</h4>
    <span v-if="(limits) && (limits.traffic) && (limits.storage)">
    <b-table-simple fixed>
      <b-thead>
        <b-th>Storage</b-th>
        <b-th>Traffic: Today</b-th>
        <b-th>Traffic: This Month</b-th>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>
            <UsageGauge :range="limits.storage"></UsageGauge>
          </b-td>
          <b-td>
            <UsageGauge :range="limits.traffic.day"></UsageGauge>
          </b-td>
          <b-td>
            <UsageGauge :range="limits.traffic.month"></UsageGauge>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </span>
  </div>
</template>

<script>
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"
import * as d3 from "d3";
import UsageGauge from "@/components/UsageGauge";

export default {
  name: 'AccountUsage',
  components: {UsageGauge},
  data() {
    return {}
  },
  mixins: [HelperFunctionsMixin],
  props: ['limits'],
  methods: {
    usageVariant(v) {
      if (typeof v == 'undefined') return "";
      if (v.limit == 0) return "";
      if (v.usage > v.limit) return 'danger';
      if (v.usage * 0.8 > v.limit) return '"warning"';
      return "";
    },
  },

}
</script>
<style scoped>
</style>