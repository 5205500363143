<template>
  <div style="margin: auto">
    <svg style="max-width: 200px" ref="gauge"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import {HelperFunctionsMixin} from "@/mixins/helperFunctionsMixin";
import Vue from "vue";

export default {
  name: "UsageGauge",
  props: ['range', 'unit'],
  mixins: [HelperFunctionsMixin],
  data() {
    return {}
  },
  watch: {
    range(old, v) {
//      console.log(old, v);
      this.updateChart();
    }
  },
  mounted() {
    Vue.nextTick(() => {
      this.updateChart();
    })
  },
  methods: {
    updateChart() {
      let width = 200,
          height = 120;

      if (!this.range || !this.range.limit) return;
      const unit = this.unit || "bytes";
      let svg = d3.select(this.$refs.gauge)
          .attr('viewBox', '0 0 ' + Math.min(width) + ' ' + Math.min(height))
          .attr('preserveAspectRatio', 'xMinYMin')
          .append("g")
          .attr("transform", "translate(" + width / 2 + "," + (height - 20) + ")");
      let arcGenerator = d3.arc();
      let q = Math.min(this.range.usage / this.range.limit, 1.0);
      let color;
      if (q > 0.9) {
        color = '#E5310E';
//        "#dc3545" boostrap danger
      } else if (q > 0.8) {
        color = '#ffc107'; // boostrap warning
      } else {
        color = "steelblue";
      }
      let pathData = arcGenerator({
        startAngle: -0.5 * Math.PI,
        endAngle: (q - 0.5) * Math.PI,
        innerRadius: 60,
        outerRadius: 80
      });
      let g = svg.append('g');
      g.append('path')
          .attr('d', d3.arc()
              .innerRadius(60)
              .outerRadius(80)
              .startAngle(-0.5 * Math.PI)
              .endAngle(0.5 * Math.PI))
          .attr("fill", "#edf2fb")
//          .attr("stroke", "black")
//          .attr("stroke-width", 2);
      g.append('path')
          .attr('d', pathData)
          .attr('fill', color)

      let fmt;
      let tooltip;
      if (unit == "bytes") {
        fmt = this.formatFileSizeGauge(this.range.usage, this.range.limit);
        tooltip = "Usage:   " + this.formatFileSize(this.range.usage) + "\n" + "Limit:   " + this.formatFileSize(this.range.limit);
      } else {
        fmt = {
          v: Number(this.range.usage || 0).toFixed(0),
          l: Number(this.range.limit).toFixed(0),
          u: "",
        }
        tooltip = "Usage:   " + Number(this.range.usage).toFixed(2) + "\n" + "Limit:   " + Number(this.range.limit).toFixed(2);
      }

      g.append('text')
          .attr('x', 0)
          .attr('y', 0)
          .attr("text-anchor", "middle")
          .text(fmt.v + " / " + fmt.l);
      g.append('text')
          .attr('x', 0)
          .attr('y', 20)
          .attr("text-anchor", "middle")
          .text(fmt.u);
      g.append("title")
          .text(tooltip);

    }
  }
}
</script>

<style scoped>

</style>