<template>
   <span>
     <strong>DNS Record:</strong><br>
     <table>
     <tr>
       <td>Type:</td>
       <td><code @click="copyElementValueToClipboard(type,this)">{{ type }}</code></td>
      </tr>
      <tr>
       <td>Name:</td>
       <td><code @click="copyElementValueToClipboard(name,this)">{{ name }}</code></td>
      </tr>
     <tr>
      <td>Value:</td>
      <td><code @click="copyElementValueToClipboard(value,this)">{{ value }}</code></td>
     </tr>
     </table>
  </span>
</template>

<style scoped>

</style>

<script>
import {defineComponent} from 'vue'
import {HelperFunctionsMixin} from "@/mixins/helperFunctionsMixin";
import {mapActions} from "vuex";

export default defineComponent({
  name: "DnsRecord",
  props: ['name', 'value', 'type'],
  mixins: [HelperFunctionsMixin],
  methods: {
    ...mapActions({alertInformation: 'alert/information', alertSuccess: 'alert/success', alertError: 'alert/error'}),
  }
})
</script>