const state = {
    currentProject : {},
    currentAccount : {},
    currentNode: {},
    currentProjectsPage: 1,
    currentNodesPage: 1
};

const actions = {
    async setCurrentProject({ dispatch, commit }, project) {
        if (!project) {
            project = {};
            commit('nodes/clearNodes', null, {root: true});
        }
        commit('setCurrentProject', project);
        await dispatch('nodes/loadNodes', null, {root: true});
        commit('hotspots/clearHotspots', null, {root:true});
    },
    async setCurrentAccount({ dispatch, commit }, account) {
        commit('setCurrentAccount', account);
        await dispatch('projects/loadProjects', null, {root: true});
    },
    setCurrentNode({ dispatch, commit }, node) {
        if (!node) {
            node = {};
            commit('hotspots/clearHotspots', null, {root: true});
        }
        commit('setCurrentNode', node);
        dispatch('hotspots/loadHotspots', null, {root: true});
        dispatch('sounds/loadSounds', null, {root: true});
    },
    setCurrentProjectsPage({commit}, page) {
        commit('setCurrentProjectsPage', page);
    },
    setCurrentNodesPage({commit}, page) {
        commit('setCurrentNodesPage', page);
    }
};

const mutations = {
    setCurrentProject(state,project) {
        if (state.currentProject != project) {
            state.currentProject = project;
            state.currentNode = {};
            state.currentNodesPage = 1;
        }
    },
    setCurrentAccount(state,account) {
        state.currentAccount=account;
    },
    setCurrentNode(state,node) {
        state.currentNode = node;
    },
    setCurrentProjectsPage(state, page) {
        state.currentProjectsPage = page;
    },
    setCurrentNodesPage(state, page) {
        state.currentNodesPage = page;
    }
};

export const navigation = {
    namespaced: true,
    state,
    actions,
    mutations
};