import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios';
import VueAxios from 'vue-axios';
import { store } from './store';
import { router } from "@/router";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faYoutube, faVimeo } from '@fortawesome/free-brands-svg-icons'
import { faUniversalAccess } from '@fortawesome/free-solid-svg-icons'

library.add(faYoutube, faVimeo, faUniversalAccess)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(VueRouter)
Vue.use(VueAxios, axios);

Vue.config.productionTip = false
Vue.prototype.$log = console.log;

axios.defaults.withCredentials = false;
axios.interceptors.response.use(
    function(response) {
        return response;
        },
    function(error) {
      // handle error
      if (error.response) {
          if (error.response.status!=200) {
              if (error.response && error.response.data && error.response.data.error) {
                  store.dispatch('alert/error',error.response.data.error);
              } else {
                  store.dispatch('alert/error',error.message);
              }
          } else {
              store.dispatch('alert/error',error.message);
          }
          if (error.response.status==401) {
              store.dispatch('user/unauthorizedRequest');
          }
      } else {
          store.dispatch('alert/error',error.message);
          console.error(error);
      }
      return error;
    });


const vm=new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

window.$vm = vm;
window.$router = router;