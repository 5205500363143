<template>
  <div style="max-width: 800px;max-height: 500px">
    <svg :id=chartid></svg>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"
import * as d3 from "d3";
import moment from "moment";

export default {
  name: 'AccountStats',
  props: ['chartid', 'range', 'set'],
  data() {
    return {
      form: {
        displayName: "-",
        email: "-"
      },
      account: {},
      data: [],
    }
  },
  mixins: [HelperFunctionsMixin],
  computed: {
    ...mapState('accounts', []),
    ...mapState('navigation', ['currentAccount']),
  },
  mounted: function () {
    this.account = this.currentAccount;
    let ofs = 0;
    if (this.$props.set) {
      this.set = this.$props.set;
    }
    const options = {set: this.set};
    if (this.set == "account") {
      options.dayofs = ofs;
    }
    if (this.set == "project") {
      options.dayofs = ofs;
    }
    this.getStats({account: this.account, options}).then(d => {
      this.data = d;
//      console.log(JSON.parse(JSON.stringify(d)));
      this.showGraph();
    });
    /*    setInterval(() => {
          ofs++;
          this.getStats({ account:this.account, offset: ofs }).then(d => {
            Object.assign(this.data, d);
            console.log(d);
         //   this.showGraph();
          });
        },5000); */
    // this.getAccount(this.account);
  },

  methods: {
    ...mapActions({alertInformation: 'alert/information'}),
    ...mapActions({
      getAccountLimits: 'accounts/getAccountLimits',
      getStats: "accounts/getStats",
      getAccount: "accounts/getAccount"
    }),
    async showGraph() {
      const data = this.data;
      const margin = {top: 25, right: 35, bottom: 25, left: 75, padd: 15};

      const fullWidth = 800;
      const fullHeight = 500;

      const width = fullWidth - margin.left - margin.right;
      const height = fullHeight - margin.bottom - margin.top;
      let svg = d3.select("#" + this.$props.chartid)
          .attr('viewBox', '0 0 ' + Math.min(fullWidth) + ' ' + Math.min(fullHeight))
          .attr('preserveAspectRatio', 'xMinYMin')
          .append("g")


      const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

      //2. Parse the dates
      const parseTime = d3.timeParse("%Y-%m-%d");
      const extend = d3.extent(this.data, function (d) {
        return parseTime(String(d.date));
      });

      if ((!extend) || (extend.length < 2) || (!extend[0]) || (!extend[1])) {
        console.log("missing data");
        return;
      }
      extend[0].setDate(extend[0].getDate() - 1);
//      console.log((extend[1] - extend[0]));
      if (extend[1]) {
        extend[1].setDate(extend[1].getDate() + 1);

        //        console.log((extend[1] - extend[0]));
        // show at least one week!
        if ((extend[1] - extend[0]) < (14 * 24 * 3600000)) {
          //          console.log(extend[0].getDate(), extend[1].getDate());
          extend[1] = new Date();
          extend[1].setTime(extend[0].getTime() + (14 * 24 * 3600000));
          //          extend[0].setDate(extend[1].getDate() - 10);
          //          console.log(7 + (extend[1] - extend[0]));
          //           console.log(extend);
        }

      }
      //3. Creating the Chart Axes
      const xTime = d3
          .scaleTime()
          .domain(extend)
          .rangeRound([0, width]);

      const x = d3.scaleBand()
          .domain(d3.timeDay.range(...xTime.domain()))
          .rangeRound([0, width])
          .padding(0.2);

      let maxTraffic = d3.max(data, function (d) {
        return d.traffic;
      });
      /*      let projects = [...new Set(data.map(d => d.project))];
            let color = d3.scaleOrdinal()
                .domain(projects)
                .range(['#e41a1c', '#377eb8', '#4daf4a']);
      */
//      if (this.limits?.traffic?.day?.limit) {
//        maxTraffic=Math.max(maxTraffic,this.limits.traffic.day.limit);
//      };
      const y = d3
          .scaleLinear()
          .domain([0, maxTraffic])
          .rangeRound([height, 0]);

      const xAxis = d3.axisBottom(x)
          .tickFormat(d => moment(d).format("MM-DD"))
          .ticks(3)
          .tickValues(x.domain().filter((d, i) => !(i % 2)));
      const yAxis = d3.axisLeft(y)
          .tickFormat(d => this.formatFileSize(d))

      //4. Creating a Line
      const line = d3
          .line()
          .x(function (d) {
            return x(parseTime(d._id));
          })
          .y(function (d) {
            return y(d.traffic);
          });

      //5. Appending the Axes to the Chart
      g.append("g")
          .attr("transform", "translate(0," + (height) + ")")
          .call(xAxis);

      g.append("g")
          .call(yAxis)
          .call(g => g.selectAll(".tick line").clone()
              .attr("x2", width)
              .attr("stroke-opacity", 0.1))
          .append("text")
          .attr("fill", "#000")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", "0.71em")
          .attr("text-anchor", "end")
          .text("Traffic");


      //6. Appending a path to the Chart
      /*      g.append("path")
                .datum(this.data)
                .attr("fill", "none")
                .attr("stroke", "steelblue")
                .attr("stroke-width", 1.5)
                .attr("d", line);

            console.log(this.data);

       */
      g.selectAll(".bar")
          .data(this.data)
          .enter().append("rect")
          .attr("class", "bar")
          .attr("x", function (d) {
            return x(parseTime(d.date));
          })
          .attr("y", function (d) {
            return y(d.traffic);
          })
          .attr("width", x.bandwidth())
          .attr("height", function (d) {
            return height - y(d.traffic);
          })
          .append("title").text(d => {
        return d._id + "\nViews: " + d.launchCnt.toLocaleString('en-US') + "\n" + "Traffic: " + this.formatFileSize(d.traffic) + "\n" + "Request: " + d.requestCnt.toLocaleString('en-US');
      });
      // console.log(this.limits.traffic.day.limit);
      if (this.account.limits?.traffic?.day) {
        let dataLimit = [this.account.limits.traffic.day];
        const trafficLine = g.selectAll(".maxtraffic")
            .data(dataLimit)
            .enter().append("g");

        trafficLine.append("line")
            .attr("class", "maxtraffic")
            .attr("x1", 0)
            .attr("x2", width)
            .attr("y1", d => y(d))
            .attr("y2", d => y(d))
            .attr("stroke", "red")
        trafficLine.append("text")
            .text(d => "Daily Limit " + this.formatFileSize(d))
            .attr("fill", "red")
            .attr("x", width - 10)
            .attr("y", d => y(d))
            .attr("dy", "1em")
            .style("font-size", "10px")
            .attr("text-anchor", "end")
      }

    }
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
</style>

<style>
.chart div {
  font: 10px sans-serif;
  background-color: steelblue;
  text-align: right;
  padding: 3px;
  margin: 1px;
  color: gray;
}

.bar {
  fill: steelblue;
}

.container-chart {
  /* width: 50%; */
  margin: auto;
}
</style>
