import moment from 'moment'

const UserRoles = ["owner", "admin", "create", "update", "read", "none"];

export const HelperFunctionsMixin = {
    data: function () {
        return {
            moment,
        }
    },
    methods: {
        confirmOpenPreview(project, nodeId) {
            this.$bvModal.msgBoxConfirm('Project will be opened in preview mode in a seperate tab.')
                .then(value => {
                    if (value) {
                        var tab;
                        if (nodeId) {
                            tab = window.open('/api/project/' + project._id + '/index.html?currentNode=' + nodeId + '&_jwt=' + this.jwt + '&ts=' + (new Date().getTime() % 10000000), 'gg_preview');
                        } else {
                            tab = window.open('/api/project/' + project._id + '/index.html?_jwt=' + this.jwt + '&ts=' + (new Date().getTime() % 10000000), 'gg_preview');
                        }
                        tab.focus();
                    }
                });
        },
        formatFileSize(bytes, decimalPoint) {
            if (typeof bytes == "undefined") return '-';
            if (bytes == null) return '-';
            if (bytes == 0) return '0\u00a0B';
            const k = 1000,
                dm = decimalPoint || 2,
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '\u00a0' + sizes[i]; // use a &nbsp
        },

        formatFileSizeGauge(bytes, limit) {
            if (typeof bytes == "undefined") return {v: '-', l: '-', u: ''};
            const k = 1000,
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                f = Math.floor(Math.log(limit) / Math.log(10)),
                i = Math.floor(f / 3);
            let dm = 2 - (f % 3);
            return {
                v: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
                l: parseFloat((limit / Math.pow(k, i)).toFixed(dm)),
                u: sizes[i],
            }
        },
        formatDate(date) {
            return date ? moment.utc(date).format('YYYY-MM-DD') : "-";
            //      return moment(date.toUTCString()).format('MMMM Do YYYY');
        },
        compareUserRoles(minRole, role) {
            if (role === undefined) return false;
            const iMin = UserRoles.indexOf(minRole);
            if (iMin < 0) {
                console.log("Unknown min role: ", minRole);
                return false;
            }
            const ind = UserRoles.indexOf(role);
            if (ind < 0) {
                console.log("Unknown role: ", role);
                return false;
            }
            // because the User Role list is sorted from highest to lowest!
            return (iMin - ind);
        },
        testUserRole(minRole, currentRole) {
            const test = this.compareUserRoles(minRole, currentRole);
            if (test === false) return false;
            return (test >= 0);
        },
        copyElementIdValueToClipboard(id) {
            let element = document.getElementById(id);
            let value = element.value;
            this.copyElementValueToClipboard(value, element);
        },
        copyElementValueToClipboard(value, element) {
            let me = this;
            if (!navigator.clipboard) {
                if (element) {
                    element.focus();
                    element.select();
                    element.setSelectionRange(0, 99999);
                    if (document.execCommand('copy')) {
                        this.alertInformation("Value copied to your clipboard.");
                    } else {
                        this.alertError("Problem copying the value to the clipboard!");
                    }
                }
            } else {
                navigator.clipboard.writeText(value).then(function () {
                    me.alertInformation("Value copied to your clipboard.");
                }, function (err) {
                    me.alertError("Error copying value to the clipboard: " + err);
                });
            }
        }
    }
}