<template>
  <div>
    <h4 class="bg-light mb-0" style="text-align: left">
      Account: <span v-if="editAccountName"><input @blur="saveAccountNameAndStopEditing()"
                                                   @keyup.enter="saveAccountNameAndStopEditing()" ref="name"
                                                   type="text" v-model="account.accountLabel" autofocus></span>
      <span v-else style="cursor: pointer;" @click="setEditing()" @keyup.enter="setEditing()">{{ account.accountLabel }}&nbsp;<b-icon
          v-if="testUserRole('admin',account.currentRole)"
          icon="pencil-fill" title="Edit Account Name"
          style="width: 16px;height: 16px;vertical-align: baseline;"/></span>
    </h4>
    <br>
  </div>
</template>

<script>
import Vue from "vue";
import {mapActions} from "vuex";
import {HelperFunctionsMixin} from "@/mixins/helperFunctionsMixin";

export default {
  name: "AccountLabel",
  props: ['account'],
  mixins: [HelperFunctionsMixin],
  data() {
    return {
      editAccountName: false,
    }
  },
  methods: {
    ...mapActions({
      updateAccount: "accounts/updateAccount",
    }),
    setEditing() {
      if (!this.testUserRole('admin', this.account.currentRole)) return;
      this.editAccountName = true;
      let me = this;
      Vue.nextTick(function () {
        me.$refs.name.focus();
      });
    },
    async saveAccountNameAndStopEditing() {
      this.editAccountName = false;
      await this.updateAccount({account: this.account, data: {accountLabel: this.account.accountLabel}});
    },
  }
}
</script>

<style scoped>

</style>