import axios from "axios";

const state = {
    hotspots : [],
    hotspotsBackup: [],
    activeHotspot: null
};

const actions = {
    async loadHotspots({ commit, dispatch, rootState }) {
        if (rootState.navigation.currentNode && rootState.navigation.currentNode._id) {
            let uri = '/api/node/' + rootState.navigation.currentNode._id + '/hotspots';
            try {
                let response = await axios.get(uri);
                if (response.status == 200) {
                    commit('setHotspots', response.data);
                    commit('backupHotspots');
                } else {
                    console.log(response);
                }
                return response;
            } catch(error) {
                console.error("There was an error!", error);
            }
        } else {
            commit('clearHotspots');
            return null;
        }
    },
    async addHotspot({ commit, dispatch, rootState }, hotspotData) {
        if (rootState.navigation.currentNode && rootState.navigation.currentNode._id) {
            let uri = '/api/node/' + rootState.navigation.currentNode._id + '/addhotspot';
            let maxHotspotId = state.hotspots.length;
            hotspotData.hotspotId = "Hotspot" + (maxHotspotId + 1);
            let response = await axios.post(uri, hotspotData);
            if (response.status == 200) {
                commit('addHotspot', response.data);
                commit('backupHotspots');
            } else {
                console.log(response);
            }
            return response;
        } else {
            return null;
        }
    },
    async saveHotspot({ commit, dispatch }, hotspot) {
        let uri = '/api/hotspot/' + hotspot._id + '/update';
        let response = await axios.put(uri, hotspot);
        if (response.status == 200) {
            let payload = {};
            payload['hotspot'] = hotspot;
            payload['hotspotSaved'] = response.data;
            commit('updateHotspotAfterSaving', payload);
            commit('backupHotspots');
        } else {
            commit('refreshHotspotsFromBackup');
        }
        return response;
    },
    async deleteHotspot({ commit }, hotspot) {
        let uri = '/api/hotspot/' + hotspot._id + '/delete';
        let response = await axios.delete(uri);
        if (response.status == 200) {
            let ind=state.hotspots.indexOf(hotspot);
            if (ind>=0) {
                commit('deleteHotspot', ind);
            } else {
                console.log("delete -hotspot not found: ", hotspot);
            }
        }
        return response;
    },
    setActiveHotspot({ commit }, hotspot) {
        commit('setActiveHotspot', hotspot);
    },
    setActiveHotspotById({ dispatch }, id) {
        for (let i = 0; i < state.hotspots.length; i++) {
            const hs = state.hotspots[i];
            if (hs._id == id) {
              dispatch('setActiveHotspot' ,hs);
              break;
            }
          }
    },
    backupHotspots({ commit }) {
        commit('backupHotspots');
    },
    refreshHotspotsFromBackup({ commit }) {
        commit('refreshHotspotsFromBackup');
    }
};

const mutations = {
    setHotspots(state, hotspots) {
        state.hotspots = [];
        state.hotspots.push(...hotspots);
    },
    addHotspot(state, hotspot) {
        state.hotspots.push(hotspot);
        state.activeHotspot = state.hotspots[state.hotspots.length - 1];
    },
    deleteHotspot(state, index) {
        state.hotspots.splice(index, 1);
        state.hotspotsBackup.splice(index, 1);
    },
    clearHotspots(state) {
        state.hotspots.splice(0);
        state.hotspotsBackup.splice(0);
        state.activeHotspot = null;
    },
    setActiveHotspot(state, hotspot) {
        state.activeHotspot = hotspot;
    },
    refreshHotspotsFromBackup(state) {
        state.hotspots = [];
        state.hotspotsBackup.forEach(element => {
            state.hotspots.push(JSON.parse(JSON.stringify(element)));
            state.hotspots[state.hotspots.length - 1].__div = element.__div;
        });
    },
    backupHotspots(state) {
        state.hotspotsBackup = [];
        state.hotspots.forEach(element => {
            state.hotspotsBackup.push(JSON.parse(JSON.stringify(element)));
            state.hotspotsBackup[state.hotspotsBackup.length - 1].__div = element.__div;
        });
    },
    updateHotspotAfterSaving(state, payload) {
        let ind = state.hotspots.indexOf(payload.hotspot);
        if (ind > -1) {
            payload.hotspotSaved.__div = payload.hotspot.__div;
            let oldHotspot = state.hotspots[ind];
            Object.assign(oldHotspot, payload.hotspotSaved);
        }
    }
};

export const hotspots = {
    namespaced: true,
    state,
    actions,
    mutations
};