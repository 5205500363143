import axios from "axios";
import Vue from "vue";

const state = {
    accounts: [],
};

const actions = {
    async loadAccounts({commit, dispatch, rootState}) {
        let uri = '/api/account/list';
        let response = await axios.get(uri);
        if (response.status == 200) {
            await commit('setAccounts', response.data);
            if (!rootState.navigation.currentAccount._id) { // set the default account
                const ca = state.accounts.find(a => a._id == rootState.user.defaultAccountId);
                if (ca) {
                    dispatch('navigation/setCurrentAccount', ca, {root: true});
                }
            }
        }
    },
    async getAccountLimits({}, account) {
        let uri = `/api/account/${account._id}/limits`;
        let response = await axios.get(uri);
        if (response.status == 200) {
            return response.data;
        }
    },
    async getStats({}, {account, options}) {
        let uri = `/api/account/${account._id}/stats`;
        let params = {...options};
//        console.log(params);
        let response = await axios.get(uri, {params});
        if (response.status == 200) {
            return response.data;
        }
    },
    async getAccount({commit}, account) {
        let uri = `/api/account/${account._id}/get`;
        let response = await axios.get(uri);
        if (response.status == 200) {
            commit("updateAccount", {account: response.data});
            return response.data;
        }
    },
    async updateAccount({commit}, {account, data}) {
        let uri = `/api/account/${account._id}/update`;
        let response = await axios.put(uri, data);
        if (response.status == 200) {
            commit("updateAccount", {account: response.data});
            return response.data;
        }
    },
    async addUser({commit}, {account, email, role}) {
        let uri = `/api/account/${account._id}/adduser`;
        let response = await axios.post(uri, {email, role});
        if (response.status == 200) {
            return response.data;
        }
    },
    async deleteUser({commit}, {account, userId}) {
        let uri = `/api/account/${account._id}/deleteuser`;
        let response = await axios.delete(uri, {params: {userId}});
        if (response.status == 200) {
            return response.data;
        }
    },
    async updateUser({commit}, {account, userId, role}) {
        let uri = `/api/account/${account._id}/updateuser`;
        let response = await axios.put(uri, {userId, role});
        if (response.status == 200) {
            return response.data;
        }
    },
    async updatePortfolio({commit}, {account, portfolio}) {
        let uri = `/api/account/${account._id}/updateportfolio`;
        let response = await axios.put(uri, {portfolio});
        if (response.status == 200) {
            return response.data;
        }
    },
    async updateSubscription({commit}, account) {
        let uri = `/api/account/${account._id}/updatesub`;
        let response = await axios.get(uri);
        if (response.status == 200) {
            commit("updatePlans", {account, plans: response.data});
            return response.data;
        }
    },
    async updateStorage({commit}, {account, storage}) {
        let uri = `/api/account/${account._id}/updatestorage`;
        let response = await axios.put(uri, storage);
        if (response.status == 200) {
            return response.data;
        }
    },
    async requestCertificate({commit}, {account, domain}) {
        let uri = `/api/account/${account._id}/requestcertificate`;
        let response = await axios.post(uri, {domain});
        if (response.status == 200) {
            return response.data;
        }
    },
    async getCertificateStatus({commit}, {account}) {
        let uri = `/api/account/${account._id}/certificatestatus`;
        let response = await axios.post(uri, {});
        if (response.status == 200) {
            return response.data;
        }
    },
    async createCloudfront({commit}, {account}) {
        let uri = `/api/account/${account._id}/createcloudfront`;
        let response = await axios.post(uri, {});
        if (response.status == 200) {
            return response.data;
        }
    },
    async deleteCertificate({commit}, {account}) {
        let uri = `/api/account/${account._id}/deletecertificate`;
        let response = await axios.delete(uri);
        if (response.status == 200) {
            return response.data;
        }
    },
    async setEnabledCloudfront({commit}, {account, value}) {
        let uri = `/api/account/${account._id}/setenabledcloudfront`;
        let response = await axios.post(uri, {value});
        if (response.status == 200) {
            return response.data;
        }
    },
    async deleteCloudfront({commit}, {account}) {
        let uri = `/api/account/${account._id}/deletecloudfront`;
        let response = await axios.delete(uri, {});
        if (response.status == 200) {
            return response.data;
        }
    },
    async setSubDomain({commit}, {account, value}) {
        let uri = `/api/account/${account._id}/updatesubdomain`;
        let response = await axios.put(uri, {subDomain: value});
        if (response.status == 200) {
            return response.data;
        }
    },
    async test({commit}, {account, value}) {
        let uri = `/api/account/${account._id}/test`;
        let response = await axios.post(uri, {value});
        if (response.status == 200) {
            return response.data;
        }
    },
    async setAsUserDefault({commit}, {account}) {
        let uri = `/api/account/${account._id}/userdefault`;
        let response = await axios.post(uri, {});
        if (response.status == 200) {
            return response.data;
        }
    },
    async toggleBeta({commit}, {account}) {
        let uri = `/api/account/${account._id}/togglebeta`;
        let response = await axios.post(uri, {});
        if (response.status == 200) {
            return response.data;
        }
    },
};

const mutations = {
    setAccounts(state, accounts) {
        state.accounts = [];
        state.accounts.push(...accounts);
    },
    updateAccount(state, {account}) {
        let oldAccount = state.accounts.find(a => a._id == account._id);
        if (oldAccount) {
            // not nice, but it works...
//            Object.assign(oldAccount, JSON.parse(JSON.stringify(account)));
            oldAccount.accountLabel = account.accountLabel;
            oldAccount.plans = account.plans;
            oldAccount.owner = account.owner;
            oldAccount.access = account.access;
            oldAccount.rights = account.rights;
        } else {
            state.accounts.push(account);
        }
    },
    updatePlans(state, {account, plans}) {
        let oldAccount = state.accounts.find(a => a._id == account._id);
        if (oldAccount) {
            oldAccount.plans = plans;
        }
    },
};

export const accounts = {
    namespaced: true,
    state,
    actions,
    mutations
};