<template>
  <div class="container-lg px-0">
        <span v-if="account.access">
          <h3 v-if="account.access.autoBlock!='none'"
              class="bg-danger">This account is currently blocked: {{ account.access.autoBlock }}</h3>
          <h3 v-if="account.access.adminBlock!='none'"
              class="bg-danger">This account is blocked by the admin: {{ account.access.adminBlock }}</h3>
        </span>
    <b-tabs card pills :vertical="windowWidth>768" active-nav-item-class="bg-secondary text-light">
      <b-tab title="Overview" active title-link-class="text-secondary">
        <AccountLabel :account="account"></AccountLabel>
        <AccountUsage :limits="limits"></AccountUsage>
        <AccountStats chartid="chart"
                      v-if="(limits)"></AccountStats>
        <b-button @click="setAsUserDefaultClick">Set as your Default Account</b-button>
      </b-tab>
      <!--      <b-tab title="Stats" active title-link-class="text-secondary"
                   v-if="(limits) && (limits.traffic) && (limits.storage)">
              <AccountLabel :account="account"></AccountLabel>
              <AccountStats chartid="chart2" set="project"></AccountStats>
            </b-tab>
       -->
      <b-tab title="Subscriptions" title-link-class="text-secondary">
        <AccountLabel :account="account"></AccountLabel>
        <AccountUsage :limits="limits"></AccountUsage>
        <br><br>
        <h2>Subscriptions</h2>
        <b-table-simple responsive>
          <b-thead>
            <b-th>Storage</b-th>
            <b-th>Traffic</b-th>
            <b-th>Users / Projects</b-th>
            <b-th>Start</b-th>
            <b-th>End</b-th>
            <b-th>Reference</b-th>
          </b-thead>
          <b-tr v-for="plan in account.plans" :key="plan._id">
            <b-td>{{ formatFileSize(plan.limit) }}</b-td>
            <b-td><span v-if="plan.trafficLimit">
          <span title="Daily Traffic">{{ formatFileSize(plan.trafficLimit.day) }}</span> / <span
                title="Monthly Traffic">{{ formatFileSize(plan.trafficLimit.month) }}</span>
        </span></b-td>
            <b-td>{{ plan.userLimit }} / {{ plan.projectLimit }}</b-td>
            <b-td>{{ formatDate(plan.start) }}</b-td>
            <b-td>{{ formatDate(plan.end) }}</b-td>
            <b-td><a :title="plan.comment" :href="planRefLink(plan)">{{ plan.referenceLink || plan.reference }}</a>
            </b-td>
          </b-tr>
        </b-table-simple>
        <span v-if="(limits.tokens>0) || (account?.tokenOrders?.length>0)">
        <br>
          <!--        <b-button @click="testClick()">Test</b-button> -->
        <h2>Tokens: {{ Number(limits.tokens).toFixed(1) }}</h2>
        <b-table-simple responsive>
          <b-thead>
            <b-th>Date</b-th>
            <b-th>Expires</b-th>
            <b-th>Amount</b-th>
            <b-th>Available</b-th>
            <b-th>Reference</b-th>
          </b-thead>
          <b-tr v-for="order in account.tokenOrders" :key="order._id"
                :style="moment.utc(order.expire).isBefore()?'text-decoration:line-through;color:#aaa;':''">
            <b-td>{{ formatDate(order.date) }}</b-td>
            <b-td>{{ formatDate(order.expire) }}</b-td>
            <b-td style="text-align: right ">{{ Number(order.amount).toFixed(1) }}</b-td>
            <b-td style="text-align: right ">{{ Number(order.available).toFixed(1) }}</b-td>
            <b-td style="text-align: left "><a :title="order.comment" :href="planRefLink(order)">{{
                order.referenceLink || order.reference
              }}</a>
            </b-td>
          </b-tr>
        </b-table-simple>
        </span>
        <br>
        <a :href="'https://' + ggnomeDomain + '/cloud-account?account=' + account.accountName + '&account_label=' + account.accountLabel"
           target="_blank" class="gg-button">Buy
          more
          traffic/storage</a>
      </b-tab>
      <b-tab title="Permissions" title-link-class="text-secondary">
        <AccountLabel :account="account"></AccountLabel>
        <h2>Permissions</h2>
        <b-table-simple hover table-variant="light">
          <b-thead class="thead-light">
            <b-th>User</b-th>
            <b-th>Role</b-th>
            <b-th></b-th>
          </b-thead>
          <b-tbody>
            <b-tr v-if="account.owner">
              <b-td>
                <span v-b-tooltip.ds200.dh0.hover.right
                      :title="'User Name: ' + account.owner.username + '\nEmail: ' + account.owner.email">
                  {{ account.owner.realname }}
                </span>
              </b-td>
              <b-td>Account Owner
              </b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr v-for="right in account.rights" :key="right._id">
              <b-td>
                <span v-if="right.user && right.user.realname" v-b-tooltip.ds200.dh0.hover.right
                      :title="'User Name: ' + right.user.username + '\nEmail: ' + right.user.email">
                  {{ right.user.realname }}
                </span>
              </b-td>
              <b-td><span v-if="testUserRole('admin',account.currentRole)">
                <b-form-select v-model="right.role" :options="roles"
                               @change="updateUserRole(right.user._id,right.role)">
                </b-form-select>
              </span>
                <span v-else>{{ right.role }}</span>
              </b-td>
              <b-td>
                <span v-if="testUserRole('admin',account.currentRole)">
                <b-icon style="cursor: pointer;" icon="trash" @click="deleteUserClick(right.user._id)"
                        title="Remove user from account."></b-icon>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <span v-if="testUserRole('admin',account.currentRole)">
        <h2 class="mt-4">Add User</h2>
        <form v-on:submit.prevent="submitAddUser">
          <b-input-group>
            <b-input-group-prepend class="d-sm-block d-none">
              <b-input-group-text>User Email</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="email" v-model="newUser.email"/>
            <b-form-select v-model="newUser.role" :options="roles">
            </b-form-select>
            <b-input-group-append>
              <b-button variant="outline-secondary" type="submit" v-b-tooltip.ds200.dh0.hover title="Add user.">
                <b-icon icon="person-plus-fill"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>
        <br>
        </span>
      </b-tab>
      <b-tab title="Portfolio Page" title-link-class="text-secondary" v-if="account.portfolio">
        <AccountLabel :account="account"></AccountLabel>
        <div class="custom-control custom-switch text-left">
          <input type="checkbox" class="custom-control-input" id="enablePortfolio" v-model="account.portfolio.enabled"
                 @input="portfolioFormEdited = true" :disabled="!testUserRole('admin',account.currentRole)">
          <label class="custom-control-label" for="enablePortfolio">Enable Portfolio Page</label>
        </div>
        <b-form class="mt-3 border rounded p-2" @input="portfolioFormEdited = true">
          <b-input-group prepend="Title">
            <b-input id="title" type="text"
                     :disabled="!account.portfolio.enabled || !testUserRole('admin',account.currentRole)"
                     v-model="account.portfolio.title"></b-input>
          </b-input-group>
          <b-input-group class="mt-3" prepend="Description">
           <textarea id="iframe" class="form-control" rows="7"
                     :disabled="!account.portfolio.enabled || !testUserRole('admin',account.currentRole)"
                     v-model="account.portfolio.description">
            </textarea>
          </b-input-group>
          <b-input-group class="mt-3" prepend="Theme">
            <b-input-group-append>
              <b-form-select v-model="account.portfolio.template">
                <b-form-select-option value="light">Light</b-form-select-option>
                <b-form-select-option value="dark">Dark</b-form-select-option>
              </b-form-select>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <div class="mt-3 text-left">
          <b-button v-if="testUserRole('admin',account.currentRole)" @click="updatePortfolioClick()"
                    :disabled="!portfolioFormEdited" variant="outline-secondary">Submit Changes
          </b-button>
        </div>
        <div v-if="account.portfolio.enabled" class="mt-4 text-left">
          <b-input-group prepend="Portfolio Link:">
            <input class="form-control" id="portfolioUrl" disabled type="text" v-model="account.portfolioUrl">
            <b-input-group-append>
              <b-button variant="outline-secondary" @click="openPortfolioInTab" v-b-tooltip.ds200.dh0.hover
                        title="Open in new tab.">
                <b-icon icon="globe2"></b-icon>
              </b-button>
              <b-button variant="outline-secondary" @click="copyElementIdValueToClipboard('portfolioUrl')"
                        v-b-tooltip.ds200.dh0.hover
                        title="Copy to clipboard.">
                <b-icon icon="files"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <!--
          <br><br><br>>
          <h2>Coming soon</h2>
          <br><br><br>
        -->
      </b-tab>
      <b-tab v-if="(account.storage) && (account.accountType!='byob')" title="Custom Domain"
             title-link-class="text-secondary" @click="showBetaClick">
        <AccountLabel :account="account"></AccountLabel>
        <span v-if="!(account?.showBeta)">

          <br><br><br>
          <h2>Coming Soon</h2>
          If you want to beta test, please write to <a href="mailto:support@ggnome.cloud">support@gnome.cloud</a>.
          <span v-if="(account.storage) && (account.storage.publicDomain)">
            <br><br><br>
            <hr>
            Custom Domain: <b-form-input type="text" disabled v-model="account.storage.publicDomain"/>
          </span>
        </span>
        <span v-else>
        <span v-if="account?.currentRole==='owner'" style="text-align: left">
          <span v-if="!(account?.publicDomain)">
            <h2>Sub-Domain</h2>
            <p style="text-align: left">Host your account on a sub domain of vtour.cloud.<br>
            Please note: You can not change the subdomain, after it has been assigned.</p>
        <div class="container">
          <span v-if="!!(account.subDomain)">
          <div class="row">
            <b-input-group>
              <b-form-input type="text" disabled v-model="account.subDomain" class="col-3"/>
              .vtour.cloud
            </b-input-group>
          </div>
          </span>
          <span v-else>
          <div class="row">
            <b-input-group>
              <b-form-input type="text" v-model="newSubdomain" class="col-3"/>
              .vtour.cloud
            </b-input-group>
             <button class="btn btn-outline-secondary float-left my-2 mt-3" @click="setSubDomainClick">Set Subdomain
            </button>
          </div>
          </span>
        </div>
       </span>
        <br>
        <br>
        <h2>Use your own domain</h2>
        <p style="text-align: left">You can host your account with your own domain, that you control.</p>
        <AccountCustomDomain @updateAccount="updateAccountValue" :account="account"></AccountCustomDomain>
        </span>
        <span v-else>
          Only the owner of the account can modify the custom domain settings!
        <span v-if="(account.storage) && (account.storage.publicDomain)" class="float-left">
          Custom Domain: <b-form-input type="text" disabled v-model="account.storage.publicDomain"/>
        </span>
        </span>
       </span>
      </b-tab>
      <b-tab title="Custom Bucket"
             v-if="(account.storage) && (account.accountType=='byob') && (testUserRole('owner',account.currentRole))"
             title-link-class="text-secondary">
        <AccountCustomBYOB @updateAccount="updateAccountValue" :account="account"></AccountCustomBYOB>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"
import AccountStats from "@/components/AccountStats";
import AccountUsage from "@/components/AccountUsage";
import AccountLabel from "@/components/AccountLabel";
import AccountCustomDomain from "@/components/AccountCustomDomain";
import AccountCustomBYOB from "@/components/AccountBYOB.vue";

export default {
  name: 'Account',
  components: {AccountCustomBYOB, AccountCustomDomain, AccountStats, AccountUsage, AccountLabel},
  data() {
    return {
      form: {
        displayName: "-",
        email: "-"
      },
      windowWidth: window.innerWidth,
      account: {},
      limits: {},
      newUser: {
        role: null,
        email: ""
      },
      betaClick: 0,
      newSubdomain: "",
      roles: [
        {value: null, text: 'Please select an option'},
        {value: 'owner', text: 'Owner'},
        {value: 'admin', text: 'Administrator'},
        {value: 'create', text: 'Create'},
        {value: 'update', text: 'Update/Modify'},
        {value: 'read', text: 'Read Only'},
      ],
      portfolioFormEdited: false,
    }
  },
  mixins: [HelperFunctionsMixin],
  computed: {
    ...mapState('accounts', ['accounts']),
    ...mapState('navigation', ['currentAccount']),
    ...mapState('user', ['userId', 'ggnomeDomain']),
  },
  mounted: function () {
    this.account = this.currentAccount;
    this.betaClick = 0;
    this.newSubdomain = this.account.subDomain || "";
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
    if (this.account) {
      this.getAccountLimits(this.account).then(l => {
        this.limits = l;
      });
      let self = this;
      this.getAccount(this.account).then(a => {
        self.account = a;
        if (this.currentAccount._id == a._id) {
          this.currentAccount.limits = a.limits;
        }
      });
      this.updateSubscription(this.account).then(p => {
        this.account.plans = p;
      });
    }
  },
  beforeRouteLeave: async function (to, from, next) {
    if (this.portfolioFormEdited) {
      this.$bvModal.msgBoxConfirm('You have unsaved changes in the portfolio page. Do you want to leave this page and discard those changes?')
          .then(value => {
            if (value) {
              next();
            }
          });
    } else {
      next();
    }
  },
  methods: {
    ...mapActions({alertInformation: 'alert/information', alertSuccess: 'alert/success', alertError: 'alert/error'}),
    ...mapActions({
      getAccountLimits: 'accounts/getAccountLimits',
      getAccount: "accounts/getAccount",
      updateAccount: "accounts/updateAccount",
      updateSubscription: "accounts/updateSubscription",
      addUser: "accounts/addUser",
      updateUser: "accounts/updateUser",
      deleteUser: "accounts/deleteUser",
      updateStorage: "accounts/updateStorage",
      updatePortfolio: "accounts/updatePortfolio",
      setSubDomain: "accounts/setSubDomain",
      test: "accounts/test",
      setAsUserDefault: "accounts/setAsUserDefault",
      toggleBeta: "accounts/toggleBeta",
    }),
    updateAccountValue(value) {
      console.log("New Value", value);
      this.account = value;
    },
    planRefLink(plan) {
      if (plan.source == "license") {
        return `https://${this.ggnomeDomain}/my-account/licensekeys/?details=${plan.reference}`;
      }
      if (plan.source == "subscription") {
        return `https://${this.ggnomeDomain}/my-account/view-subscription/${plan.referenceLink}/`;
      }
      if (plan.source == "order") {
        return `https://${this.ggnomeDomain}/my-account/view-order/${plan.reference}/`;
      }
      return "#";
    },
    async submitAddUser() {
      const rights = await this.addUser({account: this.account, email: this.newUser.email, role: this.newUser.role});
      if (rights) {
        this.account.rights = rights;
      }
    },
    async deleteUserClick(userId) {
      const rights = await this.deleteUser({account: this.account, userId});
      if (rights) {
        this.account.rights = rights;
      }
    },
    async updateUserRole(userId, role) {
      const rights = await this.updateUser({account: this.account, userId, role});
      if (rights) {
        this.account.rights = rights;
      }
    },
    async updatePortfolioClick() {
      const portfolio = await this.updatePortfolio({account: this.account, portfolio: this.account.portfolio});
      if (portfolio) {
        this.account.portfolio = portfolio;
        this.portfolioFormEdited = false;
        this.alertSuccess('Portfolio information successfully updated.');
      } else {
        this.alertError('Problem updating portfolio information!');
      }
    },
    async setSubDomainClick() {
      let r = await this.setSubDomain({account: this.account, value: this.newSubdomain});
      if (r && r.storage) {
        this.account = r;
      }
    },
    async testClick() {
      let r = await this.test({account: this.account, value: 1});
      if (r && r.storage) {
        this.account = r;
      }
      let l = await this.getAccountLimits(this.account);
      if (l) {
        this.limits = l;
      }
    },
    async setAsUserDefaultClick() {
      let r = await this.setAsUserDefault({account: this.account});
      if (r && r.storage) {
        this.account = r;
      }
    },
    async showBetaClick() {
      this.betaClick++;
      if (this.betaClick > 10) {
        this.betaClick = 0;
        let r = await this.toggleBeta({account: this.account});
        if (r && r.storage) {
          this.account = r;
        }
      }
    },
    openPortfolioInTab() {
      let tab = window.open(this.account.portfolioUrl);
      tab.focus();
    },
  }
}

</script>

<style scoped>
h1 {
  padding: 15px 0;
}
</style>
