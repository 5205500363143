<template>
  <div class="container-lg px-0">
    <b-card no-body class="rounded-0 border-0">
      <b-tabs card pills vertical active-nav-item-class="bg-secondary text-light">
        <b-tab title="Update User" active title-link-class="text-secondary">
          <b-card-text>
            <form v-on:submit.prevent="submitForm">
              <table class="mx-auto mt-3">
                <tr>
                  <td class="text-right pr-2"><label for="userName">User Name:</label></td>
                  <td><input type="text" class="form-control" id="userName" disabled :placeholder="userName"></td>
                </tr>
                <tr>
                  <td class="text-right pr-2"><label for="email">Email:</label></td>
                  <td><input type="email" class="form-control" id="email" disabled placeholder="email"
                             v-model="form.email"></td>
                </tr>
                <tr>
                  <td class="text-right pr-2"><label for="displayName">Display Name:</label></td>
                  <td><input type="text" class="form-control" id="displayName" placeholder="Display Name"
                             v-model="form.displayName"></td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button class="btn btn-outline-primary float-left my-2">Submit</button>
                  </td>
                </tr>
              </table>
            </form>
          </b-card-text>
        </b-tab>
        <b-tab v-if="false" title="Change Password" title-link-class="text-secondary">
          <form v-on:submit.prevent="submitChangePassword">
            <table class="mx-auto mt-3">
              <tr>
                <td class="text-right pr-2"><label for="old_password">Old:</label></td>
                <td><input type="password" class="form-control" id="old_password" placeholder="old password"
                           v-model="changePasswordForm.old"></td>
              </tr>
              <tr>
                <td class="text-right pr-2"><label for="new_password">New:</label></td>
                <td><input type="password" class="form-control" id="new_password" placeholder="new password"
                           v-model="changePasswordForm.new"></td>
              </tr>
              <tr>
                <td class="text-right pr-2"><label for="confirm_password">Confirm:</label></td>
                <td><input type="password" class="form-control" id="confirm_password" placeholder="confirm password"
                           v-model="changePasswordForm.confirm"></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button class="btn btn-outline-primary float-left my-2">Submit</button>
                </td>
              </tr>
            </table>
          </form>
        </b-tab>
        <b-tab title="About" title-link-class="text-secondary">
          <p class="text-left h5">Garden Gnome Cloud
          </p><br>
          <p class="text-left font-italic">
            By using this software you acknowledge that you understand and agree to the following Terms of Use:
          </p>
          <p class="text-left font-italic">
            Privacy Statement<br>
            <a href="https://ggnome.com/privacy" target="_blank">https://ggnome.com/privacy</a><br><br>
            Terms of Use<br>
            <a href="https://ggnome.com/terms" target="_blank">https://ggnome.com/terms</a>
          </p>
          <br>
          <p class="text-left font-italic">
            Support: <a href="mailto:support@ggnome.com">support@ggnome.com</a>
          </p>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'User',
  data() {
    return {
      error: '',
      form: {
        displayName: "-",
        email: "-"
      },
      changePasswordForm: {
        old: '',
        new: '',
        confirm: '',
      },
      mergeAccountForm: {
        token: '',
      }
    }
  },
  computed: {
    ...mapState('user', ['userName', 'displayName', 'email', 'mergeToken'])
  },
  mounted: function () {
    this.form.displayName = this.displayName;
    this.form.email = this.email;
  },
  methods: {
    ...mapActions('user', ['login', 'logout', 'logoutAll', 'changePassword', 'changeUser', 'createMergeToken', 'mergeAccounts']),
    ...mapActions({alertInformation: 'alert/information'}),
    submitForm() {
      this.changeUser({displayName: this.form.displayName, email: this.form.email});
    },
    async submitChangePassword() {
      let f = this.changePasswordForm;
      return await this.changePassword({oldPw: f.old, newPw: f.new, confirmPw: f.confirm});
    },
    copyTokenToClipboard() {
      navigator.clipboard.writeText(this.mergeToken);
      this.alertInformation('Merge Token copied to Clipboard.');
    },
    submitMergeAccounts() {
      let f = this.mergeAccountForm;
      this.mergeAccounts({token: f.token});
    },
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
</style>
