import axios from "axios";

const state = {
    nodes : [],
    busyLoading: false,
    autoReload: false,
};

const actions = {
    async loadNodes({commit, rootState}) {
        if (rootState.navigation.currentProject && rootState.navigation.currentProject._id) {
            let uri = '/api/project/' + rootState.navigation.currentProject._id + '/nodes';
            commit('setBusyLoading', true);
            try {
                let response = await axios.get(uri);
                if (response.status == 200) {
                    let nodes = response.data;
                    let incomplete = false;
                    nodes.forEach((node) => {
                        if (node.status != "ready") {
                            incomplete = true;
                        }
                    });
                    commit('setNodes', nodes);
                    commit('setAutoReload', incomplete);
                    return response;
                }
            } finally {
                commit('setBusyLoading', false);
            }
        } else {
            state.nodes = [];
            return null;
        }
    },
    async addNode({ commit, rootState }) {
        if (rootState.navigation.currentProject && rootState.navigation.currentProject._id) {
            let uri = '/api/project/' + rootState.navigation.currentProject._id + '/addnode';
            let maxNodeId=0;
            state.nodes.forEach(function(node) { let id=Number(node.nodeId.substr(4));if (id>maxNodeId) maxNodeId=id; });
            let response = await axios.post(uri,{ nodeId: "node" + (maxNodeId+1) });
            if (response.status == 200) {
                commit('addNode', response.data);
            }
            return response;
        }
        return false;
    },
    addNodeObject({ commit}, { node, autoReload }) {
        commit('addNode', node);
        if (autoReload) {
            commit('setAutoReload',autoReload);
        }

    },
    async saveNode({ commit, dispatch }, payload) {
        let uri = '/api/node/' + payload.node._id + '/update';
        let response = await axios.put(uri, payload.data);
        if (response.status == 200) {
            payload['nodeSaved'] = response.data;
            commit('updateNodeAfterSaving', payload);
        } else {
            dispatch('loadNodes');
        }
        return response;
    },
    async setStartNode({ dispatch }, node) {
        let uri = '/api/node/' + node._id + '/setasstart';
        let response = await axios.post(uri, {});
        if (response.status != 200) {
            dispatch('loadNodes');
        }
        return response;
    },
    async deleteNode({ commit }, node) {
        let uri = '/api/node/' + node._id + '/delete';
        let response = await axios.delete(uri);
        if (response.status == 200) {
            let ind = state.nodes.indexOf(node);
            if (ind >= 0) {
                commit('deleteNode', ind);
            }
        }
        return response;
    },
    async reorderNodes({}, project) {
        let nn=project.name;
        if ((nn!=null) && (nn!="")) {
            let uri = '/api/project/' + project._id + '/reordernodes';
            let nodeOrder=[];
            state.nodes.forEach(function(node) { if (node.project==project._id) nodeOrder.push(node.nodeNumId); });
            console.log(nodeOrder);
            let response = await axios.put(uri, nodeOrder);
            if (response.status == 200) {
                console.log("order updated");
            }
            return response;
        }
    },
};

const mutations = {
    setNodes(state, nodes) {
        state.nodes = [];
        state.nodes.push(...nodes);
    },
    addNode(state, node) {
        state.nodes.push(node);
    },
    deleteNode(state, index) {
        state.nodes.splice(index, 1);
    },
    clearNodes(state) {
        state.nodes.splice(0);
    },
    setBusyLoading(state, busy) {
        state.busyLoading = busy;
    },
    setAutoReload(state, value) {
        state.autoReload = value;
    },
    updateNodeAfterSaving(state, payload) {
        let ind = state.nodes.indexOf(payload.node);
        if (ind > -1) {
            let oldNode = state.nodes[ind];
            Object.assign(oldNode, payload.nodeSaved);
        }
    }
};

export const nodes = {
    namespaced: true,
    state,
    actions,
    mutations
};