<template>
  <b-modal id="share-modal" title="Share Project" header-bg-variant="light" hide-footer>
    <form>
      <label for="url" class="form-control-label">Project Link:</label>
      <b-input-group>
        <input class="form-control" id="url" disabled type="text" v-model="projectLink">
        <b-input-group-append>
          <b-button variant="outline-secondary" @click="openLinkInTab" v-b-tooltip.ds200.dh0.hover
                    title="Open in new tab.">
            <b-icon icon="globe2"></b-icon>
          </b-button>
          <b-button variant="outline-secondary" @click="copyToClipboard('url')" v-b-tooltip.ds200.dh0.hover
                    title="Copy to clipboard.">
            <b-icon icon="files"></b-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <label for="iframe" class="form-control-label mt-3">Embed:</label>
      <b-input-group>
           <textarea id="iframe" class="form-control" rows="7" cols="50" disabled>
              <iframe width="640" height="480" src="{{projectLink}}" frameborder="0" allow="accelerometer; magnetometer; gyroscope; fullscreen; autoplay; clipboard-write; encrypted-media; xr-spatial-tracking; gamepad;" allowfullscreen></iframe>
            </textarea>
        <b-input-group-append>
          <b-button variant="outline-secondary" @click="copyToClipboard('iframe')" v-b-tooltip.ds200.dh0.hover
                    title="Copy to clipboard.">
            <b-icon icon="files"></b-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <label for="qr" class="form-control-label mt-3">QR Code:</label>
      <b-input-group class="d-flex">
        <b-input-group-prepend class="flex-fill border rounded-left" style="background-color: #e9ecef">
          <div style="padding: 10px">
            <div style="border: 10px solid white">
              <qrcode-vue id="qr" :value="projectLink" size="200" level="M"/>
            </div>
          </div>
        </b-input-group-prepend>
        <b-input-group-append>
          <b-button class="float-right" variant="outline-secondary" @click="qrToFile()" v-b-tooltip.ds200.dh0.hover
                    title="Save as image.">
            <b-icon icon="download"></b-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </form>
  </b-modal>
</template>

<script>
import {mapActions} from 'vuex'
import QrcodeVue from 'qrcode.vue'
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"

export default {
  name: 'ShareModal',
  data() {
    return {}
  },
  components: {
    QrcodeVue,
  },
  mixins: [HelperFunctionsMixin],
  props: ['publicUrl'],
  computed: {
    projectLink: function () {
      return this.publicUrl;
    }
  },
  methods: {
    ...mapActions({alertSuccess: 'alert/success', alertInformation: 'alert/information', alertError: 'alert/error'}),
    copyToClipboard(id) {
      var me = this;
      this.$bvModal.hide('share-modal');
      let element = document.getElementById(id);
      let value = element.value;
      this.copyElementValueToClipboard(value, element);
    },
    async qrToFile() {
      this.$bvModal.hide('share-modal');
      try {
        let div = document.getElementById('qr');
        let canv = div.firstChild;
        let link = document.createElement('a');
        link.download = 'qrcode.png';
        link.href = canv.toDataURL("image/png");
        link.click();
      } catch (err) {
        this.alertError("Error saving image: " + err.message);
      }
    },
    async copyQrToClipboard() {
      this.$bvModal.hide('share-modal');
      try {
        let div = document.getElementById('qr');
        let canv = div.firstChild;
        canv.toBlob((blob) => {
          navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]).then(() => {
            this.alertInformation("Image copied to clipboard.");
          }).catch((e) => {
            this.alertError("Problem copying image to the clipboard!");
          });
        }, "image/png");
      } catch (err) {
        this.alertError("Error copying image to the clipboard: " + err.message);
      }
    },
    openLinkInTab() {
      this.$bvModal.hide('share-modal');
      let tab = window.open(this.projectLink + "?ts=" + (new Date().getTime() % 10000000), 'gg_public');
      tab.focus();
    }
  }
}
</script>