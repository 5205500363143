<template>
  <span>
                <h3>Bucket configuration</h3>
        A custom bucket allows you, to use your own S3 bucket with the gnome.cloud.
        <b-form style="text-align: left" v-on:submit.prevent="submitUpdateByob">
          <label for="bucket">Bucket name:</label>
          <b-form-input name="bucket" type="text" v-model="account.storage.bucket"/>
          <b-form-checkbox v-model="account.storage.endpoint.enabled">
            Custom Endpoint
          </b-form-checkbox>
          <span v-if="account.storage.endpoint.enabled">
          <label for="endpurl">Endpoint Url:</label>
          <b-form-input name="endpurl" type="text" v-model="account.storage.endpoint.url"/>
          <label for="endacid">Access Key Id:</label>
          <b-form-input name="endacid" type="text" v-model="account.storage.endpoint.accessKeyId"/>
          <label for="endsec">Secret Access Key:</label>
          <b-form-input name="endsec" type="text" v-model="account.storage.endpoint.secretAccessKey"/>
          </span>
          <label class="mt-3" for="pubprefix">Public Prefix:</label>
          <b-form-input type="text" name="pubprefix" v-model="account.storage.publicPrefix"/>
          <!---
            <label>Private Prefix</label>
            <b-form-input type="text" v-model="account.storage.privatePrefix"/>
          -->
          <label class="mt-3">Public Url:</label>
          <div class="container">
            <div class="row">
              <b-input-group>
                <b-form-select v-model="account.storage.publicProtocol" class="col-2">
                  <b-form-select-option value="http://">http://</b-form-select-option>
                  <b-form-select-option value="https://">https://</b-form-select-option>
                </b-form-select>
                <input class="form-control col-7" type="text"
                       v-model="account.storage.publicDomain" placeholder="example.org"/>
                <b-form-input type="text" v-model="account.storage.publicUrlPrefix" class="col-3"
                              placeholder="/tours/"/>
              </b-input-group>
              <div class="warning" v-if="!checkDomain()">Invalid domain name!</div>
            </div>
          </div>
          <label class="mt-3" for="invalidationHookUrl">Invalidation Hook URL:</label>
          <b-form-input type="text" name="invalidationHookUrl" v-model="account.storage.invalidationHookUrl"/>
          <hr>
          <div>
            Your settings mean for example that the file:
            <pre>s3://{{ account.storage.bucket }}/{{ account.storage.publicPrefix }}index.html</pre>
            is mapped to the public URL:
            <pre>{{ account.storage.publicProtocol }}{{
                account.storage.publicDomain
              }}{{ account.storage.publicUrlPrefix }}index.html</pre>
          </div>
          <button class="btn btn-outline-secondary float-left my-2 mt-3">Submit</button>
          <br style="clear: both">
          <p><span v-html="bucketReport"></span></p>
        </b-form>
        <hr style="clear: both">
        <span v-if="!account.storage.endpoint.enabled">
        <h3>Permission changes</h3>
        <div style="text-align: left;">
          <p>Please add the gnome cloud AWS user <code>arn:aws:iam::603238511527:user/ggloud</code> to your buckets
            <i>Permissions - Bucket policy</i> and allow the actions <code>
              "s3:GetObject", "s3:PutObject", "s3:DeleteObject", "s3:ListBucket"</code>.
          </p>
          <p>Example policy:</p>
        </div>
<div v-if="account.storage.bucket">   <pre
    style="text-align: left;tab-size: 2; border: black 1px solid; padding: 8px;">
{
  "Version": "2012-10-17",
  "Statement": [
<span style="background: yellow">    {
      "Sid": "GnomeCloudAccess",
      "Effect": "Allow",
      "Principal": {
         "AWS": "arn:aws:iam::603238511527:user/ggloud"
      },
      "Action": [
        "s3:GetObject",
        "s3:PutObject",
        "s3:DeleteObject",
        "s3:ListBucket"
      ],
      "Resource": [
        "arn:aws:s3:::{{ account.storage.bucket }}/{{ account.storage.publicPrefix }}*",
        "arn:aws:s3:::{{ account.storage.bucket }}"
      ]
    },</span>
		{
			"Sid": "PublicRead",
			"Effect": "Allow",
			"Principal": "*",
			"Action": ["s3:GetObject"],
			"Resource": "arn:aws:s3:::{{ account.storage.bucket }}/{{ account.storage.publicPrefix }}*"
		}
	]
}</pre>
  </div>
<div v-else>
  Bucket name is missing!
</div>
        </span>
   </span>
</template>

<script>
import Vue from 'vue'
import {mapState, mapActions} from 'vuex'
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"
import AccountStats from "@/components/AccountStats";
import AccountUsage from "@/components/AccountUsage";
import AccountLabel from "@/components/AccountLabel";
import axios from "axios";

export default {
  name: 'AccountCustomBYOB',
  props: ['account'],
  data() {
    return {
      form: {
        displayName: "-",
        email: "-"
      },
      windowWidth: window.innerWidth,
      bucketReport: "",
    }
  },
  mixins: [HelperFunctionsMixin],
  computed: {
    ...mapState('accounts', ['accounts']),
    ...mapState('navigation', ['currentAccount']),
    ...mapState('user', ['userId']),
  },
  mounted: function () {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    ...mapActions({alertInformation: 'alert/information', alertSuccess: 'alert/success', alertError: 'alert/error'}),
    ...mapActions({
      getAccount: "accounts/getAccount",
      updateStorage: "accounts/updateStorage",
    }),

    async submitUpdateByob() {
      this.bucketReport = "";
      const storage = await this.updateStorage({account: this.account, storage: this.account.storage});
      if (storage) {
        this.account.storage = storage;
        let uri = `/api/account/${this.account._id}/testbyob`;
        let response = await axios.post(uri, {});
        if (response.status == 200) {
          console.log(response.data);
          this.bucketReport = response.data.report;
          return response.data;
        }
      }
    },
    checkDomain() {
      if (!this?.account?.storage?.publicDomain) return true;
      if (this.account?.storage?.publicDomain?.length < 5) return true;
      const pattern = /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/;
      return pattern.test(this.account.storage.publicDomain);
    }
  }
}

</script>

<style scoped>
h1 {
  padding: 15px 0;
}

.warning {
  color: #E5310E;
}

</style>
