<template>
  <div class="container-lg bg-light px-0 pt-2">
    <div class="px-2">
      <!--      <b-button id="addProjectBtn" class="ml-3 float-left mt-1 mb-2 button-responsive" size="md" variant="outline-secondary" @click="addNewProject" v-b-tooltip.ds200.dh0.hover title="Add new project."><b-icon icon="plus"></b-icon></b-button> -->
      <!--      <b-popover triggers="" variant="info" target="addProjectBtn" placement="bottomright" title="Add Project"><div>To get started, add your first project by clicking this button.</div></b-popover> -->
      <b-pagination v-if="projects && (projects.length > perPage)" v-model="currentPage" :total-rows="rows"
                    :per-page="perPage" aria-controls="projects-table" class="float-right mt-1" @change="pageChanged">
      </b-pagination>
    </div>
    <span class="h5 mr-2">Accounts:</span>
    <b-dropdown id="accountsDropdown" variant="outline-dark" class="mb-2" size="sm" no-flip>
      <template #button-content>
              <span>
                <b-icon icon='three-dots-vertical'></b-icon> {{ currentAccount.accountLabel }}
              </span>
      </template>
      <b-dropdown-item-button v-for="account in accounts" :key="account._id" variant="dark" class="btn-sm"
                              @click="cbSetCurrentAccount(account);">
        {{ account.accountLabel }}
      </b-dropdown-item-button>
    </b-dropdown>
    <b-button id="accountSettingsBtn" class="ml-3 mb-2 button-responsive" size="sm" variant="outline-secondary"
              @click="openAccount" v-b-tooltip.ds200.dh0.hover title="Account Settings">
      <b-icon icon="gear-fill"></b-icon>
    </b-button>
    <!--
       <b-button id="accountStatsBtn" class="ml-3 mt-1 mb-2 button-responsive" size="md" variant="outline-secondary"
                 @click="openStats" v-b-tooltip.ds200.dh0.hover title="Account Stats.">
         <b-icon icon="graph-up"></b-icon>
       </b-button>
   -->
    <span v-if="currentAccount && currentAccount.access">
      <h3 v-if="currentAccount.access.autoBlock!='none'"
          class="bg-danger"
          style="text-align: left;padding-left:10px;margin-top: 10px">This account is currently blocked: {{
          currentAccount.access.autoBlock
        }}</h3>
       <h3 v-if="currentAccount.access.adminBlock!='none'"
           class="bg-danger">This account is blocked by the admin: {{ currentAccount.access.adminBlock }}</h3>
    </span>

    <b-table-simple v-if="projects && (projects.length > 0)" id="projects-table" class="table table-hover" responsive>
      <thead class="thead-light">
      <tr>
        <th style="width:20%;text-align: left;">Project</th>
        <th style="width:30%;text-align: left;">Name</th>
        <th style="width:10%;text-align: right;">Views</th>
        <th style="width:15%;text-align: right;" class="d-sm-table-cell d-none">Size</th>
        <th style="width:25%;text-align: left;"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="project in projectsForPage" :key="project._id">
        <td style="text-align: left;">
          <img class="table-thumbnail" :src="project.publicUrl + 'preview.jpg'"
               @keyup.enter="setCurrentProjectAndGotoNodes(project)" tabindex="0"
               :alt="'Thumbnail Image of Project' + project.name">
        </td>
        <td style="text-align: left;">
          <span v-if="project==editProject"><input @blur="saveProjectNameAndStopEditing(project)"
                                                   @keyup.enter="saveProjectNameAndStopEditing(project)" ref="name"
                                                   type="text" v-model="project.name" autofocus></span>
          <span v-else style="cursor: pointer;" @click="setEditing(project)" @keyup.enter="setEditing(project)"
                tabindex="0">{{ project.name }} <b-icon icon="pencil-fill" title="Edit Project Name"/></span>
        </td>
        <td style="text-align: right;">
          <span v-if="!isByob && project.stats.total">{{ project.stats.total.launchCnt }}</span>
          <span v-else>-</span>&nbsp;
          <b-icon icon="box-arrow-in-up-right" @click="openProjectPublicUrl(project)" style="cursor: pointer"
                  title="Open project in new tab."></b-icon>
        </td>
        <td style="text-align: right;" class="d-sm-table-cell d-none">
          <span class="d-none d-sm-block" v-if="project.size>0">{{ formatFileSize(project.size) }}</span><br>
        </td>
        <td style="text-align: left;">
          <b-spinner
              v-if="busyDeletingProject == project || busyPublishingProject == project || busyUnpublishingProject == project"></b-spinner>
          <b-dropdown v-else variant="outline-dark" size="sm">
            <template #button-content>
              <span>
                <b-icon icon='three-dots-vertical'></b-icon><span class="d-sm-inline d-none"> Actions</span>
              </span>
            </template>
            <b-dropdown-item-button variant="dark" class="btn-sm" @click="openProjectPublicUrl(project)">
              <b-icon icon="box-arrow-in-up-right"></b-icon>
              Open Project
            </b-dropdown-item-button>
            <!--            <b-dropdown-item-button class="btn-sm" variant="dark" @click="setCurrentProjectAndGotoNodes(project)">
                          <b-icon icon="pencil-fill"></b-icon> Edit
                        </b-dropdown-item-button>
                        <b-dropdown-item variant="dark" class="btn-sm" @click="confirmOpenPreview(project)">
                          <b-icon icon="binoculars-fill"></b-icon> Preview
                        </b-dropdown-item> -->
            <b-dropdown-item-button v-if="(project.published)" variant="dark" class="btn-sm"
                                    @click="shareUrl=project.publicShareUrl; $bvModal.show('share-modal')">
              <b-icon icon="share"></b-icon>
              Share
            </b-dropdown-item-button>

            <!--        <b-dropdown-item-button v-if="project.published" variant="dark" class="btn-sm" @click="unpublishProjectWithFeedback(project)">
                          <b-icon icon="eye-slash-fill"></b-icon> Make Private
                        </b-dropdown-item-button> -->

            <span v-if="testUserRole('admin',project.currentRole) && (!isByob)">
              <b-dropdown-item-button v-if="project.published" variant="dark" class="btn-sm"
                                      @click="selectedProject=project; $bvModal.show('access-modal')">
                <b-icon icon="shield-lock"></b-icon>
                Limit Access
              </b-dropdown-item-button>
            </span>
            <span v-if="testUserRole('create',project.currentRole)">
              <b-dropdown-item-button v-if="project.portfolio" variant="dark" class="btn-sm"
                                      @click="setProjectPortfolio(project,false)">
                <b-icon icon="journal-minus"></b-icon>
                Remove from Portfolio
              </b-dropdown-item-button>
              <b-dropdown-item-button v-else variant="dark" class="btn-sm" @click="setProjectPortfolio(project,true)">
                <b-icon icon="journal-plus"></b-icon>
                Add to Portfolio
              </b-dropdown-item-button>
            </span>
            <b-dropdown-item-button v-if="!isByob" variant="dark" class="btn-sm" @click="loadStats(project)"
                                    v-b-modal="'statistics-modal-' + project._id">
              <b-icon icon="graph-up"></b-icon>
              Statistics
            </b-dropdown-item-button>
            <span v-if="testUserRole('create',project.currentRole)">
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button variant="warning" class="btn-sm" @click="confirmDeleteProject(project)">
                <b-icon icon="trash"></b-icon>
                Delete
              </b-dropdown-item-button>
            </span>
          </b-dropdown>
          &nbsp;
          <b-icon v-if="(project.access) && (project.access.restriction!='none')" icon="lock-fill"
                  :title="project.access.restriction"></b-icon>
          <b-icon class="ml-1" v-if="project.portfolio" icon="star-fill" title="In Portfolio"></b-icon>

        </td>
        <b-modal variant="info" title="Project Statistics" centered ok-only
                 :id="'statistics-modal-' + project._id"
                 header-bg-variant="light"
                 footer-bg-variant="light">
          <table class="w-100" style="text-align: right">
            <thead>
            <th class="w-25"></th>
            <th class="w-25">Views</th>
            <th class="w-25">Traffic</th>
            <th class="w-25">Requests</th>
            </thead>
            <tr v-if="currentStats.day">
              <td>Today:</td>
              <td align="right">{{ currentStats.day.launchCnt }}</td>
              <td align="right">{{ formatFileSize(currentStats.day.transferBytes) }}</td>
              <td align="right">{{ currentStats.day.requestCnt }}</td>
            </tr>
            <tr v-if="currentStats.month">
              <td>This Month:</td>
              <td align="right">{{ currentStats.month.launchCnt }}</td>
              <td align="right">{{ formatFileSize(currentStats.month.transferBytes) }}</td>
              <td align="right">{{ currentStats.month.requestCnt }}</td>
            </tr>
            <tr v-if="currentStats.total">
              <td>Total:</td>
              <td align="right">{{ currentStats.total.launchCnt }}</td>
              <td align="right">{{ formatFileSize(currentStats.total.transferBytes) }}</td>
              <td align="right">{{ currentStats.total.requestCnt }}</td>
            </tr>
          </table>
        </b-modal>
      </tr>
      <b-tfoot v-if="(projects.length<5)">
        <td style="height: 200px">&nbsp;<!-- hack for the menu to fully show -->
        </td>
      </b-tfoot>
      </tbody>
    </b-table-simple>
    <b-card v-if="(!busyLoading) && projects && (projects.length == 0)" no-body class="rounded-0 border-0">
      <h3 class="mt-3">There are no projects in this account yet.</h3>
      <h3>Create and upload your first project with <a href="https://ggnome.com/pano2vr"
                                                       target="_blank">Pano2VR 7</a> or <a
          href="https://ggnome.com/object2vr"
          target="_blank">Object2VR 4</a><br/>
        <br/><img class="mt-3"
                  src="/assets/pano2vr_256.png"
                  width="200">
        <img class="mt-3"
             src="/assets/object2vr_256.png"
             width="200"></h3>
    </b-card>
    <ShareModal id="share" :publicUrl="shareUrl"></ShareModal>
    <AccessModal id="access" :project="selectedProject"></AccessModal>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapState, mapActions} from 'vuex'
import ShareModal from "@/components/ShareModal"
import AccessModal from "@/components/AccessModal"
import {PublishProjectMixin} from "../mixins/publishProjectMixin"
import {HelperFunctionsMixin} from "../mixins/helperFunctionsMixin"

export default {
  name: 'Projects',
  mixins: [PublishProjectMixin, HelperFunctionsMixin],
  components: {
    ShareModal,
    AccessModal,
  },
  data() {
    return {
      editProject: null,
      currentPage: 1,
      perPage: 10,
      dlProjectName: '',
      dlProjectId: '',
      tmpProjectName: '',
      shareUrl: '',
      selectedProject: {},
      currentStats: {}
    }
  },

  created: function () {
    this.$root.$emit('bv::hide::popover');
    this.setCurrentProject(null);
    if (this.loggedIn) {
      this.loadProjects();
      this.loadAccounts();
    }
    this.currentPage = this.currentProjectsPage;
  },
  computed: {
    ...mapState('navigation', ['currentProject', 'currentProjectsPage', 'currentAccount']),
    ...mapState('projects', ['projects', 'busyDeletingProject', 'busyLoading']),
    ...mapState('accounts', ['accounts']),
    ...mapState('user', ['loggedIn', 'jwt']),
    rows: function () {
      return this.projects ? this.projects.length : 0;
    },
    isByob: function () {
      return this.currentAccount?.accountType == 'byob';
    },
    projectsForPage: function () {
      return ((this.projects && this.projects.length > this.perPage) ? this.projects.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage) : this.projects);
    },
  },
  methods: {
    ...mapActions('projects', ['loadProjects', 'addProject', 'saveProjectName', 'deleteProject', 'cloneProject', 'publishProject', 'getProjectStats', 'updateProject']),
    ...mapActions('accounts', ['loadAccounts']),
    ...mapActions('navigation', ['setCurrentProject', 'setCurrentProjectsPage', 'setCurrentAccount']),
    ...mapActions({alertSuccess: 'alert/success', alertError: 'alert/error'}),

    setEditing(project) {
      this.editProject = project;
      this.tmpProjectName = project.name;
      let me = this;
      Vue.nextTick(function () {
        me.$refs.name[0].focus();
      });
    },
    async addNewProject() {
      let response = await this.addProject();
      if (response.status == 200) {
        this.currentPage = Math.ceil((this.rows) / this.perPage);
        this.setCurrentProjectAndGotoNodes(this.projects[this.projects.length - 1]);
      }
    },
    saveProjectNameAndStopEditing(project) {
      if (project.name != '') {
        this.saveProjectName(project);
      } else {
        this.alertError('The project name must not be empty!');
        project.name = this.tmpProjectName;
      }
      this.editProject = null;
    },
    confirmDeleteProject(project) {
      if (project.portfolio) {
        this.$bvModal.msgBoxOk('This project is in your portfolio page and cannot be deleted. You first have to remove it from the portfolio.');
      } else {
        this.$bvModal.msgBoxConfirm('Are you sure?')
            .then(value => {
              if (value) {
                this.deleteProject(project);
              }
            });
      }
    },
    async setProjectPortfolio(project, flag) {
      await this.updateProject({project, data: {portfolio: flag}});
      project.portfolio = flag;
    },
    setCurrentProjectAndGotoNodes(project) {
      this.setCurrentProject(project);
      this.$router.push({path: '/nodes', query: {projectId: project._id}});
    },
    cbSetCurrentAccount(account) {
      this.setCurrentAccount(account);
      this.$router.replace({path: this.$route.path, query: {accountId: account._id}});
      this.currentPage = 1;
    },
    pageChanged(page) {
      this.setCurrentProjectsPage(page);
    },
    closeAllPopovers() {
      this.$root.$emit('bv::hide::popover');
    },
    openAccount() {
      this.$router.push({path: '/account', query: {accountId: this.currentAccount._id}});
    },
    openStats() {
      this.$router.push({path: '/stats'});
    },
    openProjectPublicUrl(project) {
      let tab = window.open(project.publicIndexUrl + "?ts=" + (new Date().getTime() % 10000000), 'gg_public');
      tab.focus();
    },
    async loadStats(project) {
      this.currentStats = {};
      this.currentStats = await this.getProjectStats(project);
    },
  },
  watch: {
    projects: function (newVal) {
      if (newVal.length > 0) {
        this.$root.$emit('bv::hide::popover');
      } else {
        this.$root.$emit('bv::show::popover');
      }
    }
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}

.popover {
  max-width: 100%;
}
</style>
