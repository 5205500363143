<template>
  <div>
    <br>
    <br>
    <a href="/auth/ggnome">
      <img width="200px" src="/assets/gglogo.svg"/>
      <br><br>
      <b-button>
        <b-icon icon="power"></b-icon>
        Log In
      </b-button>
    </a>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div @click="showForm=!showForm">...</div>
    <form v-if="showForm" v-on:submit.prevent="submitForm">
      {{ error }}
      <table class="mx-auto mt-3">
        <tr>
          <td><label for="username"></label></td>
          <td><input type="text" autocomplete="no" class="form-control" id="username" placeholder="Username"
                     v-model="form.username"></td>
        </tr>
        <tr>
          <td><label for="password"></label></td>
          <td><input type="password" autocomplete="no" class="form-control" id="password" placeholder="Password"
                     v-model="form.password"></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <b-button variant="outline-secondary" type="submit" class="float-left my-2">Submit</b-button>
          </td>
        </tr>
      </table>
    </form>
    <!-- <a href="/auth/google">Sign In with Google</a> <br>
    <a href="/auth/facebook">Sign In with Facebook</a> -->

  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      error: '',
      form: {
        username: '',
        password: '',
      },
      showForm: false,
    }
  },
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapActions('user', ['login', 'logout']),
    submitForm() {
      this.login({username: this.form.username, password: this.form.password});
    }
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
</style>
