<template>
<div>Root Page</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Root',
  computed: {
    ...mapState('user', ['loggedIn','hideProjects']),
  },
  created: function()
  {
    if (this.loggedIn) {
      if (!this.hideProjects) {
        this.$router.replace('/projects');
      }
    } else {
      this.$router.replace('/login');
    }
  },
}
</script>

<style scoped>

</style>