import axios from "axios";

const state = {
    sounds : [],
    soundsBackup: [],
    backgroundSound: null
};

const actions = {
    loadSounds({ commit, dispatch, rootState }) {
        if (rootState.navigation.currentNode && rootState.navigation.currentNode._id) {
            let uri = '/api/node/' + rootState.navigation.currentNode._id + '/sounds';
            let promise = axios.get(uri);
            promise.then(
                (response) => {
                    if (response.status == 200) {
                        commit('setSounds', response.data);
                    }
                },
                (error) => {
                    console.log(error.response)
                }).catch(error => {
                console.error("There was an error!", error);
            });
            return promise;
        } else {
            commit('clearSounds');
            return null;
        }
    },
    addSound({ commit, dispatch, rootState }, soundData) {
        if (rootState.navigation.currentNode && rootState.navigation.currentNode._id) {
            let uri = '/api/node/' + rootState.navigation.currentNode._id + '/addsound';
            let promise = axios.post(uri, soundData);
            promise.then((response) => {
                if (response.status == 200) {
                    commit('addSound', response.data);
                } else {
                    console.log(response);
                }
            });
            return promise;
        } else {
            return null;
        }
    },
    saveSound({ commit, dispatch }, sound) {
        let uri = '/api/sound/' + sound._id + '/update';
        let promise = axios.put(uri, sound);
        promise.then(
          (response) => {
            if (response.status != 200) {
                commit('refreshSoundsFromBackup');
            }});
        return promise;
    },
    deleteSound({ commit }, sound) {
        let uri = '/api/sound/' + sound._id + '/delete';
        let promise = axios.delete(uri);
        promise.then((response) => {
            if (response.status == 200) {
                let ind=state.sounds.indexOf(sound);
                if (ind>=0) {
                    commit('deleteSound', ind);
                    if (sound.soundId == '_background') {
                        commit('setBackgroundSound', null);
                    }
                }
            }
        });
        return promise;
    },
    backupSounds({ commit }) {
        commit('backupSounds');
    },
    refreshSoundsFromBackup({ commit }) {
        commit('refreshSoundsFromBackup');
    }
};

const mutations = {
    setSounds(state, sounds) {
        state.sounds = [];
        state.sounds.push(...sounds);
        state.backgroundSound = state.sounds.find(element => element.soundId == '_background');
    },
    addSound(state, sound) {
        state.sounds.push(sound);
        if (sound.soundId == '_background') {
            state.backgroundSound = sound;
        }
},
    deleteSound(state, index) {
        state.sounds.splice(index, 1);
    },
    clearSounds(state) {
        state.sounds.splice(0);
        state.soundsBackup.splice(0);
        state.backgroundSound = null;
    },
    refreshSoundsFromBackup(state) {
        state.sounds = [];
        state.soundsBackup.forEach(element => {
            state.sounds.push(JSON.parse(JSON.stringify(element)));
        });
        state.backgroundSound = state.sounds.find(element => element.soundId == '_background');
    },
    backupSounds(state) {
        state.soundsBackup = [];
        state.sounds.forEach(element => {
            state.soundsBackup.push(JSON.parse(JSON.stringify(element)));
        });
    },
    setBackgroundSound(state, sound) {
        state.backgroundSound = sound;
    }
};

export const sounds = {
    namespaced: true,
    state,
    actions,
    mutations
};