import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";

let stored_token=window.ggUserToken;
try {
    if (!stored_token) stored_token = sessionStorage.getItem('user_token');
    if (!stored_token) stored_token = localStorage.getItem('user_token');
} catch(e) {
    console.log(e);
}

if (stored_token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + stored_token;
}

import { alert } from './alert.js';
import { accounts } from './accounts.js';
import { user } from './user.js';
import { navigation } from './navigation.js';
import { projects } from './projects.js';
import { nodes } from './nodes.js';
import { hotspots } from './hotspots.js';
import { sounds } from './sounds.js';

if (stored_token) {
    user.state.loggedIn = true;
    user.state.token = stored_token;
}

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        user,
        accounts,
        alert,
        navigation,
        projects,
        nodes,
        hotspots,
        sounds
    }
});
