import { BToast } from 'bootstrap-vue'

const actions = {
    success({}, message) {
        let bToaster = new BToast;
        bToaster.$bvToast.toast(message, {
            title: 'Success',
            autoHideDelay: 3000,
            appendToast: true,
            variant: 'success'
        });
    },
    information({}, message) {
        let bToaster = new BToast;
        bToaster.$bvToast.toast(message, {
            title: 'Information',
            autoHideDelay: 3000,
            appendToast: true,
            variant: 'info'
        });
    },
    error({}, message) {
        let bToaster = new BToast;
        bToaster.$bvToast.toast(message, {
            title: 'Error',
            noAutoHide: true,
            appendToast: true,
            variant: 'danger'
        });
    }
};

export const alert = {
    namespaced: true,
    actions
};