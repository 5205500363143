<template>
  <div>
    <form v-on:submit.prevent="submitForm">
      {{error}}
      <button class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data(){
    return {
      error:'',
    }
  },
  methods:{
    submitForm(){
      axios.get('/api/logout',{withCredentials: true})
          .then((res) => {
            console.log(res);
            //Perform Success Action
            var r = res.data;
            if (r.error) {
              this.error=r.error;
            }
            if (r.username) {
              this.$router.push('/projects')
            }
          })
          .catch((error) => {
            // error.response.status Check status code
            console.log(error)
          }).finally(() => {
        //Perform action in always
      });
    }
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
</style>
