<template>
<div><h1>Page not found!</h1></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PageNotFound',
  computed: {
    ...mapState('user', ['loggedIn']),
  },
  created: function()
  {
    if (this.loggedIn) {
      this.$router.push('/projects');
    } else {
      this.$router.push('/login');
    }
  },
}
</script>


<style scoped>

</style>